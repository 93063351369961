<template>
   <div id="big_box">
    <div id="small_box">
        <div id="form_box">
          <form>
            <p><input type="text" name="name" placeholder="用户名" v-model="form.name"></p>
            <p><input type="password" name="pw" placeholder="密码" v-model="form.pwd">  </p>
            <p><input type="button" name="submit" value="登录" @click="handleSubmit"></p>
          </form>
        </div>
    </div>
   </div>
</template>

<script>
export default {
    data(){
        return {
            form:{
                name:"",
                pwd:"",
            }
        }
    },
    methods:{
         async handleSubmit(){
           //post执行后返回一个promise对象
            let resVal = await this.$http.post("/user/checkLogin",this.form).catch((err)=>{
                 console.warn(err)
              })
           let result = resVal.data
           if (result.code==0){
             //登录态相关信息，交给vuex存储
             this.$store.commit("Login/getRealName",result.data)
             await this.$router.push("/main_new");
           }else{
             alert("帐号或密码不对")
           }
         }
    }
}
</script>

<style scoped>
      #big_box{
        width:100vw;
        height:100vh;
        background:url(../assets/img/bgx.jpg) no-repeat;
        background-size:cover;
      }

      #small_box{
            position: absolute;
            right: 15%;
            bottom:40%;
            width:400px;
            height: 200px;
            background-color:rgb(0,0,0,0.2);
            padding:20px;
      }
</style>
